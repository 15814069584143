import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  CONVERT_UNITS_MAP,
  MEASUREMENT_SYSTEM as MEASUREMENT_UNITS_SETTINGS,
} from 'src/utils/constants';
import { convertToUserUnits, getUnitsSettings } from 'src/utils/conversions';

const ConvertedUnits = ({ quantity, units, addUnitsParentheses, className, skipTooltip }) => {
  if (quantity === null || quantity === undefined || !units) return null;

  const { measurementSystem, customVolumeUnit, customWeightUnit } = getUnitsSettings();

  const unitSystemComponents = {
    [MEASUREMENT_UNITS_SETTINGS.METRIC]: 'Metric',
    [MEASUREMENT_UNITS_SETTINGS.IMPERIAL]: 'Imperial',
    [MEASUREMENT_UNITS_SETTINGS.CUSTOM]: 'Custom',
  };

  const {
    quantity: convertedQuantity,
    units: convertedUnits,
    isConverted,
  } = convertToUserUnits(quantity, units);

  const displayCustomUnitSystem = () => {
    if (measurementSystem !== MEASUREMENT_UNITS_SETTINGS.CUSTOM) return null;
    return (
      <>
        <div>
          Volume Units: <strong>({customVolumeUnit})</strong>
        </div>
        <div>
          Weight Units: <strong>({customWeightUnit})</strong>
        </div>
      </>
    );
  };

  const displayUnitSystem = unitSystemComponents[measurementSystem] || 'Metric';

  // Function to render the units with or without parentheses
  const renderUnits = () => {
    if (addUnitsParentheses) {
      return `(${convertedUnits})`;
    }
    return convertedUnits;
  };

  if (!isConverted) {
    return (
      <span className={className}>
        {/* Used converted units map here to change units to short forms. No conversion from two different
        unit actually occur here from users unit settings. */}
        {quantity} {CONVERT_UNITS_MAP[units]}
      </span>
    );
  }

  if (skipTooltip) {
    return (
      <span className={className}>
        {convertedQuantity} {renderUnits()}
      </span>
    );
  }

  return (
    <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip id='converted-units-tooltip'>
          <p className='mb15'>
            The value and units are displayed according to your <strong>Measurement Units</strong>{' '}
            selection <strong>({displayUnitSystem})</strong>
          </p>

          <div>{displayCustomUnitSystem()}</div>

          <div className='mt10'>
            <div>
              Original Value: <strong>{quantity}</strong>
            </div>
            <div>
              Original Units: <strong>({units})</strong>
            </div>
          </div>
        </Tooltip>
      }
    >
      <span className={className}>
        {convertedQuantity} {renderUnits()}
        <FontAwesomeIcon icon={faInfoCircle} color='#FFA500' className='spacer-left' />
      </span>
    </OverlayTrigger>
  );
};

ConvertedUnits.defaultProps = {
  addUnitsParentheses: false,
  className: '',
  skipTooltip: false,
};

ConvertedUnits.propTypes = {
  quantity: PropTypes.number.isRequired,
  units: PropTypes.string.isRequired,
  addUnitsParentheses: PropTypes.bool,
  className: PropTypes.string,
  skipTooltip: PropTypes.bool,
};

export default ConvertedUnits;
